<template>
    <b-card title="Edit Flag Expiry">
        <b-col cols="12">
            <label>Name</label>
            <b-form-input placeholder="Project Name" v-model="flagExpiry.name"></b-form-input>
        </b-col>

        <b-col cols="12">
            <label>Duration (Month)</label>
            <b-form-input placeholder="Extend Month" v-model="flagExpiry.month"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-2">
            <b-button variant="success" @click="doUpdateflagExpiry">Update</b-button>
        </b-col>
    </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            flagExpiry: {},
        }
    },
    mounted() {
        this.getBaseWarrantyAll().then((data) => {
            // // BAD !!!
            // let id = this.$route.params.id;
            // let warranty = data.filter((w) => {
            //     if (w._id === id) {
            //         return true;
            //     }

            //     return false;
            // })
            console.log(data)
            let find = data.find((x)=>x.typeOfWarranty === "Remarks")
            console.log({find})

            this.flagExpiry = find;
        })
    },
    methods: {
        ...mapActions({
            getBaseWarrantyAll: 'warranty/getBaseWarrantyAll',
            updateFlagExpiry: 'warranty/updateFlagExpiry'
        }),
        doUpdateflagExpiry() {
            this.updateFlagExpiry(this.flagExpiry).then(async () => {
                this.$bvToast.toast("Successfully Updated Flag Expiry", {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1000);
                this.$router.go(-1);
            }).catch((e) => {
                console.error(e)
                this.$bvToast.toast(`Update Flag Expiry Failed`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });
            })
        }
    }
}
</script>